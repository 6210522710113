<template>
  <div class="df-dropdown-list">
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          v-if="icon"
          class="list__icon"
          icon
          plain
          small
          v-bind="attrs"
          v-on="on"
          :ripple="false"
        >
          <font-awesome-icon :color="grayIconColor" :icon="icon" />
        </v-btn>
        <df-button
          v-else
          icon="chevron-down"
          outlined
          variant="text-secondary"
          v-bind="attrs"
          v-on="on"
          :color-icon="greenIconColor"
          :ripple="false"
        >
          {{ buttonText }}
        </df-button>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="item.action"
        >
          <v-list-item-title :style="item.color ? { color: item.color } : null">
            <font-awesome-icon
              v-if="item.icon"
              class="mb-1"
              :icon="item.icon"
            />
            {{ item.name }}
            <span v-if="Object.keys(item).includes('quantity')">
              {{ item.quantity }}
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'

export default {
  name: 'DfDropdownList',

  components: {
    DfButton,
  },

  props: {
    buttonText: {
      default: '',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    items: {
      required: true,
      type: Array,
      validator(value) {
        return value.every((item) => {
          return (
            typeof item.name === 'string' &&
            (typeof item.color === 'string' || item.color === undefined) &&
            (typeof item.icon === 'string' || item.icon === undefined) &&
            (typeof item.quantity === 'number' ||
              item.quantity === undefined) &&
            typeof item.action === 'function'
          )
        })
      },
    },
  },

  computed: {
    grayIconColor() {
      return '#5B6459'
    },
    greenIconColor() {
      return '#39AF49'
    },
  },
}
</script>

<style lang="scss" scoped>
.df-dropdown-list {
  max-width: fit-content !important;

  .list__icon {
    min-width: 20px;
  }
}
.v-menu__content {
  margin-top: 4px;
  border-radius: 8px !important;
  border: 1px solid #e6e9e6;
  box-shadow: 6px 4px 7px 0px rgba(219, 219, 219, 0.4) !important;
}
::v-deep .v-list {
  padding: 0px;
}
::v-deep .v-list-item {
  height: 40px;
  padding: 12px 16px;
}
::v-deep .v-list-item__title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  span {
    border: 1px solid #e6e9e6;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 12px;
  }
}
.v-btn::before {
  background-color: transparent !important;
}
.v-btn:hover {
  background-color: #d8f2dc !important;
  box-shadow: none !important;
}
</style>
